<template>
    <section class="tables">
        <div class="row">
            <vue-snotify></vue-snotify>
            <div class="col-md-6 d-flex align-items-stretch grid-margin">
                <div class="row flex-grow">
                    <div class="col-12 grid-margin">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title" v-if="this.id == ''">Add New Client</h4>
                                <h4 class="card-title" v-else>Update Client</h4>
                                <form class="forms-sample" @submit.stop.prevent="onSubmit">
                                    <b-form-group label="Name*" label-for="name">
                                        <b-form-input
                                                type="text"
                                                id="name"
                                                name="name"
                                                placeholder="Client Name"
                                                v-model="name"
                                        >
                                        </b-form-input>
                                    </b-form-group>
                                    <b-form-group label="URL*" label-for="url">
                                        <b-form-input type="text" id="url" placeholder="client-name.watchspotter.app"
                                                      v-model="url"></b-form-input>
                                    </b-form-group>
                                    <b-form-group label="City" label-for="city">
                                        <b-form-input type="text" id="city" placeholder="City"
                                                      v-model="city"></b-form-input>
                                    </b-form-group>
                                    <b-button type="submit" variant="success" class="mr-2">
                                        <span v-if="this.id == ''">Submit</span>
                                        <span v-else>Update</span>
                                    </b-button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script>
    import router from "../router"
    import Vue from "vue";
    import SortedTablePlugin from "vue-sorted-table";
    import API from '@/api';
    import Snotify, {SnotifyPosition} from 'vue-snotify'

    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    Vue.use(SortedTablePlugin, {
        ascIcon: '<i class="ti-arrow-down"></i>',
        descIcon: '<i class="ti-arrow-up"></i>'
    });
    export default {
        name: 'Clients',
        data() {
            return {
                name: "",
                url: "",
                city: "",
                id: ""
            };
        },
        mounted() {
            if (this.$route.params.id != undefined) {
                this.id = this.$route.params.id;
                this.get_client();
            }

        },
        methods: {
            onSubmit() {
                if (this.name != '' && this.url != '') {
                    let data = {
                        name: this.name,
                        url: this.url,
                        city: this.city,
                        id: this.id
                    }
                    API.add_edit_client(
                        data,
                        data => {
                            this.$snotify.warning(data, {
                                timeout: 2000,
                                showProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            this.clear_form();
                        },
                        err => {
                        }
                    )
                } else {
                    this.$snotify.warning('Please fill required fields', {
                        timeout: 2000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
            },
            get_client() {
                let data = {
                    id: this.id
                }
                API.get_client(
                    data,
                    data => {
                        this.name = data.name;
                        this.url = data.url;
                        this.city = data.city;
                    },
                    err => {
                    }
                )
            },
            clear_form() {
                this.name = "";
                this.url = "";
                this.city = "";
                this.id = "";
            }
        }
    }
</script>
